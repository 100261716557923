/**
 * TODO
 * - some paddings and margins can be unified
 * - some parts of this component can be applied in others OfferCards
 */
import dynamic from 'next/dynamic';
import NextLink from 'next/link';
import PropTypes from 'prop-types';
import { twJoin } from 'tailwind-merge';

import Text from 'shopper/components/Text';

import Anchor from 'components/Anchor';
import Image from 'components/Image';

import useExclusiveOffer from 'hooks/useExclusiveOffer';

import { sendEvent } from 'lib/gtag';
import { getOfferImageLink, toOfferImageAltText } from 'lib/image';
import { toOfferLink } from 'lib/links';
import { toFormattedOfferOldPrice, toFormattedOfferPrice } from 'lib/offer';
import { noop } from 'lib/utils';

import { useUser } from 'providers/UserProvider';

import Price from '../../Shopper/Price';
import Tag from '../../Shopper/Tag';
import OfferGridCardStoreSection from './OfferGridCardStoreSection';

import OFFER from 'constants/offer';

const OfferGridCardFooter = dynamic(() => import('./Footer'));

const OfferGridCard = ({
  authors = [],
  cardTitleAs = 'span',
  gaEventCategory,
  likesCount,
  offerComments,
  offerId,
  offerImagePriority = false,
  offerOldPrice = null,
  offerPhoto,
  offerPrice,
  offerPriceType,
  offerPublished,
  offerSlug,
  offerStatusName,
  offerTags,
  offerTitle,
  offerUserVisibility,
  showFooter,
  storeDomain,
  userId,
  userName,
  userPhoto,
  onImageClick = noop,
  onTitleClick = noop,
}) => {
  const { isOfferBlocked, onUnloggedUserTriesOpenExclusiveOffer } =
    useExclusiveOffer();
  const { isLoggedIn } = useUser();

  const onClick = (event) => {
    if (!isOfferBlocked(offerUserVisibility)) {
      return;
    }

    sendEvent({
      action: 'click__offer__exclusive',
      category: gaEventCategory,
      logged: isLoggedIn,
    });

    event.preventDefault();
    onUnloggedUserTriesOpenExclusiveOffer(offerSlug);
  };

  return (
    <NextLink href={toOfferLink(offerSlug)} prefetch={false} passHref>
      <Anchor
        className="relative flex h-full select-none flex-col justify-between rounded-4 p-4 shadow-sm transition-shadow hover:shadow-md lg:p-6"
        onClick={onClick}
      >
        <div className="relative flex size-full flex-row lg:flex-col">
          <OfferGridCardStoreSection
            className="hidden pb-4 lg:flex"
            storeDomain={storeDomain}
          />
          <div className="relative flex justify-center">
            <Image
              alt={toOfferImageAltText(offerTitle)}
              className={twJoin(
                'flex size-[120px] self-center rounded-4 md:size-[200px]',
                offerStatusName === OFFER.STATUSES.FINISHED && 'opacity-30'
              )}
              grayscale={offerStatusName === OFFER.STATUSES.FINISHED}
              height={120}
              loading={offerImagePriority ? null : 'lazy'}
              priority={offerImagePriority}
              sizes="(max-width: 768px) 120px, 200px"
              src={getOfferImageLink(offerPhoto, 120)}
              srcSet={`
                  ${getOfferImageLink(offerPhoto, 180)} 180w,
                  ${getOfferImageLink(offerPhoto, 300)} 300w,
                `}
              width={120}
              onClick={onImageClick}
            />
            {offerTags.length > 0 && (
              <div className="absolute -bottom-1 left-0 flex">
                <Tag className="whitespace-pre px-2 py-1" size="size2" filled>
                  {offerTags[0]?.name}
                </Tag>
              </div>
            )}
          </div>
          <div className="ml-6 flex size-full flex-col justify-between border-b border-neutral-high-300 pb-4 dark:border-neutral-low-300 lg:ml-0">
            <OfferGridCardStoreSection
              className="lg:hidden"
              storeDomain={storeDomain}
            />
            <Text
              as={cardTitleAs}
              className="mt-3 line-clamp-2 min-h-12 w-full break-words"
              onClick={onTitleClick}
            >
              {offerTitle}
            </Text>
            <Price
              className="mt-2"
              primaryInfo={toFormattedOfferPrice(offerPrice, offerPriceType)}
              secondaryInfo={toFormattedOfferOldPrice(
                offerOldPrice,
                offerPriceType
              )}
            />
          </div>
        </div>
        {showFooter && (
          <OfferGridCardFooter
            authors={authors}
            gaEventCategory={gaEventCategory}
            likesCount={likesCount}
            offerComments={offerComments}
            offerId={offerId}
            offerPublished={offerPublished}
            userId={userId}
            userName={userName}
            userPhoto={userPhoto}
          />
        )}
      </Anchor>
    </NextLink>
  );
};

OfferGridCard.propTypes = {
  authors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      photo: PropTypes.string.isRequired,
      typeName: PropTypes.string.isRequired,
      specialityName: PropTypes.string,
      level: PropTypes.number.isRequired,
      link: PropTypes.string.isRequired,
    })
  ),
  cardTitleAs: PropTypes.string,
  gaEventCategory: PropTypes.string.isRequired,
  offerComments: PropTypes.number.isRequired,
  offerIsHighlight: PropTypes.bool.isRequired,
  offerImagePriority: PropTypes.bool,
  offerOldPrice: PropTypes.number,
  offerPhoto: PropTypes.string.isRequired,
  offerPrice: PropTypes.number.isRequired,
  offerPriceType: PropTypes.string.isRequired,
  offerPublished: PropTypes.string.isRequired,
  offerSlug: PropTypes.string.isRequired,
  offerStatusName: PropTypes.string.isRequired,
  offerUserVisibility: PropTypes.string.isRequired,
  offerTags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ),
  offerTitle: PropTypes.string.isRequired,
  showFooter: PropTypes.bool.isRequired,
  storeDomain: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  userPhoto: PropTypes.string.isRequired,
  userUsername: PropTypes.string.isRequired,
  onImageClick: PropTypes.func,
  onTitleClick: PropTypes.func,
};

export default OfferGridCard;
