import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

import Icon from 'shopper/components/Icon';
import Text from 'shopper/components/Text';

const OfferGridCardStoreSection = ({ className, storeDomain }) => (
  <div className={twMerge('flex items-center', className)}>
    <Text
      as="span"
      className="mr-1 block max-w-36 truncate whitespace-nowrap lg:max-w-full"
      size="size2"
    >
      {storeDomain}
    </Text>
    <Icon
      className="text-neutral-high-400 dark:text-neutral-high-400"
      name="verified"
      size="size3"
    />
  </div>
);

OfferGridCardStoreSection.propTypes = {
  storeDomain: PropTypes.string.isRequired,
};

export default OfferGridCardStoreSection;
